import React from "react";
import { Layout } from "../../components/layout";
import { GridOneText } from "../../components/grids";
import { H1UppercaseGrey } from "../../components/headings";
import styled from "styled-components";
import { Link } from "gatsby";

export default function Impressum() {
    const TextDiv = styled.div`
        font-size: 1rem;
        text-align: left;
        @media screen and (max-width: 813px) {
            font-size: 0.9rem;
            h1 {
                font-size: 1.5rem;
                text-align: center;
            }
        }
    `;
    return (
        <Layout title="Imprint" lang="en" actSite="impressum">
            <GridOneText>
                <TextDiv>
                    <H1UppercaseGrey>Imprint</H1UppercaseGrey>
                    Metallbau Kessler GmbH & Co. KG
                    <br />
                    Sautierstraße 23
                    <br />
                    78187 Geisingen
                    <br />
                    Tel. 0 77 04 92799 0<br />
                    Fax: 0 77 04 92799-10
                    <br />
                    E-Mail:{" "}
                    <Link to="mailto:info@kessler-metall.de">
                        info@kessler-metall.de
                    </Link>
                    <br />
                    <br />
                    Local Court Stuttgart, HRA 728084
                    <br />
                    <br />
                    CEO: Waldemar Maier
                    <br />
                    Data Protection Officer: Irina Maier
                    <br />
                    <br />
                    <strong>
                        Personally liable partner of GmbH{" & "}
                        Co.KG:
                    </strong>
                    <br />
                    W. Maier Verwaltungs-GmbH
                    <br />
                    Seat: Geisingen
                    <br />
                    Local Court Stuttgart HRB 743864
                    <br />
                    Managing Director: Waldemar Maier
                    <br />
                    <br />
                    Additional information according to the law on legal
                    framework conditions for the electronic business
                    transactions (EGG) in connection with § 6 of the
                    Teleservices Act (TDG) Sales tax identification number
                    according to § 27 a Sales Tax Act: DE814846718
                </TextDiv>
            </GridOneText>
        </Layout>
    );
}
