import React from "react"

export function H1UppercaseGrey({ children }) {
    return (
        <h1 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif", textTransform: "uppercase" }}>{children}</h1>
    )
}

export function H2UppercaseGrey({ children }) {
    return (
        <h2 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif", textTransform: "uppercase" }}>{children}</h2>
    )
}

export function H3UppercaseGrey({ children }) {
    return (
        <h3 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif", textTransform: "uppercase" }}>{children}</h3>
    )
}

export function H4UppercaseGrey({ children }) {
    return (
        <h4 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif", textTransform: "uppercase" }}>{children}</h4>
    )
}

export function H1Grey({ children }) {
    return (
        <h1 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif" }}>{children}</h1>
    )
}

export function H2Grey({ children }) {
    return (
        <h2 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif" }}>{children}</h2>
    )
}

export function H3Grey({ children }) {
    return (
        <h3 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif" }}>{children}</h3>
    )
}

export function H4Grey({ children }) {
    return (
        <h4 style={{ color: "grey", fontFamily: "Helvetica, arial, sans-serif" }}>{children}</h4>
    )
}
