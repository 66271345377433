import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import styled from "styled-components"
import { HeroButtonDark } from "./buttons"
import { H4UppercaseGrey } from "./headings"

export const GridOneText = styled.div`
    padding-right: 15%;
    padding-left: 15%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;

    @media screen and (max-width: 813px) {
        padding-right: 5%;
        padding-left: 5%;
        /* text-align: left; */
    }
`

export const GridOneMedia = styled.div`
    padding-right: 15%;
    padding-left: 15%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    @media screen and (max-width: 813px) {
        padding-right: 0%;
        padding-left: 0%;
    }
`

const TwoGridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* max-height: 750px; */
    width: 100%;
    @media screen and (max-width: 813px) {
        max-height: unset;
    }
`

const TwoGridElement = styled.div`
    flex: 1 0 50%;
    /* text-align:  */
    min-width: 500px;
    @media screen and (max-width: 813px) {
        min-width: 51vw;
        max-width: 100%;
    }
`

export function GridTwo( props ) {
    const leftFlex  = (props.leftFlex) ? props.leftFlex : "50";
    const rightFlex = (props.rightFlex) ? props.rightFlex : "50";
    return (
        <TwoGridContainer>
            <TwoGridElement css={css`
                text-align: right;
                flex: 1 0 ${leftFlex}%;
            `}>
                { props.left }
            </TwoGridElement>
            <TwoGridElement css={css`
                text-align: left;
                flex: 1 0 ${rightFlex}%;
            `}>
                { props.right }
            </TwoGridElement>
        </TwoGridContainer>
    )
}

export function GridThree( props ) {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", height: "auto", width: "100%"}}>
            <div style={{ flex: "1 0 33%", textAlign: "center", maxHeight: "750px", minWidth: "400px" }}>
                { props.left }
            </div>
            <div style={{ flex: "1 0 33%", textAlign: "center", maxHeight: "750px", minWidth: "400px" }}>
                { props.center }
            </div>
            <div style={{ flex: "1 0 33%", textAlign: "center", maxHeight: "750px", minWidth: "400px" }}>
                { props.right }
            </div>
        </div>
    )
}

export function GridFour( props ) {
    const FlexRowWrap = styled.div`
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        height: auto;
        width: 100%;
    `

    const FlexElem50 = styled.div`
        flex: 1 0 50%;
        min-width: 500px;

        @media screen and (max-width: 813px) {
            min-width: 100%;
        }
    `
    return (
        <FlexRowWrap>
            <FlexElem50>
                <FlexRowWrap>
                    <FlexElem50>
                        { props.left }
                    </FlexElem50>
                    <FlexElem50>
                        { props.centerLeft }
                    </FlexElem50>
                </FlexRowWrap>
            </FlexElem50>
            <FlexElem50>
                <FlexRowWrap>
                    <FlexElem50>
                        { props.centerRight }
                    </FlexElem50>
                    <FlexElem50>
                        { props.right }
                    </FlexElem50>
                </FlexRowWrap>
            </FlexElem50>
        </FlexRowWrap>
    )
}

export function PicturedTextLink( props ) {
    const PtlContainer = styled.div`
        margin: 10%;
        text-align: left;
        background-color: #FBFBFB;
        transition: all ease 0.5s;

        :hover {
            img {
                transform: scale(110%);
            }
        }

        @media screen and (max-width: 813px) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 5%;
            margin-top: 5%;
        }

        @media (prefers-reduced-motion) {
            transition: none;
            :hover {
                img {
                    transform: none;
                }
            }
        }
    `
    const PtlText = styled.div`
        padding: 16px;
        height: 260px;
        max-width: 100%;

        p {
            font-size: 0.98em;
        }
        @media screen and (max-width: 813px) {
            height: fit-content;
            font-size: 0.9rem;
        }
    `
    return (
        <PtlContainer>
            <Img fluid={props.picture} imgStyle={{ transition: "all ease 0.5s" }} style={{ height: "250px", width: "100%", objectFit: "cover", maxHeight: "250px", margin: "0px" }} />
            <PtlText>
                <H4UppercaseGrey>{props.heading}</H4UppercaseGrey>
                <p style={{  }}>{props.text}</p>
                {() => {
                        if (props.to) {
                            return(<HeroButtonDark to={props.to} text="Mehr erfahren" />)
                        }
                    }
                }
            </PtlText>
        </PtlContainer>
    )
}

export function HorSep( props ) {
    const data = useStaticQuery(
        graphql`
            query {
                wideSep: file(relativePath: { eq: "DCS_m_Wohnbau.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2300, webpQuality: 100, jpegQuality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `
    )
    return (
        <Img fluid={data.wideSep.childImageSharp.fluid} style={{ width: "100%", height: props.height, objectFit: "cover", filter: "brightness(85%)" }} />
    )
}

const HorSepAnimContainer = styled.div`
    width: 100%;
    display: flex;
`

const HorSepAnimImage = styled.div`
    flex: auto;
    flex-grow: 1;
    padding: 0px;
    height: 100%;
    transition: ease 600ms;

    :hover {
        flex-grow: 2;
    }


    @media screen and (max-width: 813px) {
        :hover {
            flex-grow: 8;
        }
    }
    .img,
    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        transition: ease 400ms;
        filter: grayscale(30%);
    }

    .gatsby-image-wrapper:hover {
        filter: grayscale(0%);
    }

    @media (prefers-reduced-motion) {
        :hover {
            flex-grow: 1;
        }
    }
`

export function HorSepAnim( props ) {
    /* props.images must contain a lot of <Img fluid /> stuff
    * props.height must contain the pixel height of the separator
    * props....                                                        */
    const images = props.images.map(elem => {
        return (
            <HorSepAnimImage>
                {elem}
            </HorSepAnimImage>
        )
    })
    return (
        <HorSepAnimContainer style={{ height: props.height }}>
            {images}
        </HorSepAnimContainer>
    )
}